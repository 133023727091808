import { GenericResponse, MonthlyReport } from "../interfaces";
import { Tags } from "../store/tags";
import financeApi from "./financeApi";

const reportsApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getMonthlyReport: builder.query<GenericResponse<MonthlyReport[]>, void>({
      query: () => ({
        url: "ctequery/monthlyreport",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<MonthlyReport[]>) => {
        const sortedData = response.result.slice(0, 12);
        return {
          ...response,
          result: sortedData,
        };
      },
      providesTags: [Tags.MonthlyReport],
    }),
  }),
});

export const { useGetMonthlyReportQuery } = reportsApi;
export default reportsApi;
