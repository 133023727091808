import { hocAuthenticate } from "../components/hoc";
import { useGetAllAccountQuery } from "../services/accountsApi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MainLoader, CountryFlag } from "../common";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { AccountData } from "../interfaces";

function Accounts() {
  //const [accounts, setAccounts] = useState<AccountData[]>([]);
  const { data, isLoading } = useGetAllAccountQuery();

  const getCurrencySymbol = (currencyId: number) => {
    switch (currencyId) {
      case 1:
        return "₹";
      case 2:
        return "RM";
      case 3:
        return "$";
      default:
        return "";
    }
  };

  const getCountryCode = (currencyId: number) => {
    switch (currencyId) {
      case 1:
        return "IN"; // India
      case 2:
        return "MY"; // Malaysia
      case 3:
        return "US"; // United States
      default:
        return ""; // Default case for unknown currency
    }
  };

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card dashboard-tables">
          <DataTable
            value={data?.result || []}
            header="ACCOUNTS"
            stripedRows
            showGridlines
            size="small"
            tableStyle={{ minWidth: "50rem" }}
            scrollable
            scrollHeight="35vh"
          >
            <Column field="sequentialId" header="ID"></Column>
            <Column
              field="name"
              header="Name"
              body={(rowData: AccountData) => {
                const countryCode = getCountryCode(rowData.currencyId);
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CountryFlag countryCode={countryCode} />
                    <span style={{ marginLeft: "8px" }}>{rowData.name}</span>
                  </div>
                );
              }}
            ></Column>
            <Column field="accountcode" header="Code"></Column>
            <Column
              field="lastTransactionDate"
              header="Last Activity Date"
            ></Column>
            <Column
              field="balance"
              header="Balance"
              body={(rowData: AccountData) =>
                `${getCurrencySymbol(
                  rowData.currencyId
                )} ${rowData.balance.toLocaleString()}`
              }
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default hocAuthenticate(Accounts);
