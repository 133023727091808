import React, { useState } from "react";
import { NavLink } from "react-router-dom"; // Import NavLink
import SidebarItem from "./SidebarItem";

const Sidebar: React.FC = () => {
  const [activeMenuItem, setActiveMenuItem] = useState<string>("");

  const menuItems = [
    { text: "Dashboard", iconClass: "ni ni-tv-2", path: "/dashboard" },
    {
      text: "Statistics",
      iconClass: "ni ni-calendar-grid-58 calendar-color",
      path: "/statistics",
    },
    {
      text: "Reports",
      iconClass: "ni ni-credit-card credit-color",
      path: "/reports",
    },
    {
      text: "Statements",
      iconClass: "ni ni-app app-color",
      path: "/statements",
    },
    {
      text: "Configuration",
      iconClass: "ni ni-world-2 config-color",
      path: "/configuration",
    },
    {
      text: "Transactions",
      iconClass: "far fa-credit-card transact-color",
      path: "/transactions",
    },
  ];

  const handleMenuItemClick = (text: string) => {
    setActiveMenuItem(text);
  };

  return (
    <div className="sidenav">
      <h2>Finance Manager</h2>
      <ul className="sidenav-ul">
        {menuItems.map((item) => (
          <NavLink
            to={item.path} // Set the path for navigation
            key={item.text}
            className={`sidenav-item ${
              activeMenuItem === item.text ? "active" : ""
            }`} // Add active class
            onClick={() => handleMenuItemClick(item.text)} // Set active item on click
          >
            <SidebarItem
              key={item.text}
              text={item.text}
              iconClass={item.iconClass}
              active={activeMenuItem === item.text}
              onMenuClick={() => handleMenuItemClick(item.text)}
            />
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
