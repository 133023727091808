import { CommonTransactions } from "../data";
import WelcomeUser from "../components/header/WelcomeUser";
import TransactionForm from "../containers/TransactionForm";
import { useState } from "react";
import { ICommonTransaction } from "../interfaces";
import { TransactionCards } from "../components/smartcard";
import { useGetMonthlyReportQuery } from "../services/reportsApi";

import "../styles/templates/transaction.css";

function Transactions() {
  const [selectedTransaction, setSelectedTransaction] =
    useState<ICommonTransaction>({
      account: "",
      type: "",
      component: "",
      description: "",
      classifier: "",
      mode: "",
      people: "",
      state: "A",
      total: 0,
    });

  const { data, isLoading } = useGetMonthlyReportQuery();
  const currentData = data?.result[0];

  return (
    <div className="transact-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <TransactionCards data={currentData} isLoading={isLoading} />
      </div>
      <div className="transact-form-panel">
        <TransactionForm commonTransaction={selectedTransaction} />
      </div>
      <CommonTransactions onClickTransaction={setSelectedTransaction} />
    </div>
  );
}

export default Transactions;
