import React from "react";

interface SmartCardItemProps {
  heading: string;
  dataPoint: string;
  iconClass: string;
  iconColor: string;
  trend: string;
}

const SmartCardItem: React.FC<SmartCardItemProps> = ({
  heading,
  dataPoint,
  trend,
  iconClass,
  iconColor,
}) => {
  return (
    <div className="smartcard-frame">
      <div className="sc-top-section">
        <div className="sc-header">
          <span>{heading}</span>
        </div>
        <div className="sc-datapoint">
          <span>{dataPoint}</span>
        </div>
        <div className="sc-icon">
          <div className={iconColor}>
            <i className={iconClass}></i>
          </div>
        </div>
      </div>
      <div className="sc-bottom-section">
        <span>{trend}</span>
      </div>
    </div>
  );
};

export default SmartCardItem;
