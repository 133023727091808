import {
  IRecentTransaction,
  GenericResponse,
  ICommonTransaction,
} from "../interfaces";
import { Tags } from "../store/tags";
import financeApi from "./financeApi";

const transactionsApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecentTransactions: builder.query<
      GenericResponse<IRecentTransaction[]>,
      void
    >({
      query: () => ({
        url: "transaction/recent",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<IRecentTransaction[]>) => {
        const modifiedResult = response?.result.map((item, index) => ({
          ...item,
          sequentialId: index + 1,
        }));

        return {
          ...response,
          result: modifiedResult,
        };
      },
    }),

    getAccountTransactions: builder.query<
      GenericResponse<IRecentTransaction[]>,
      void
    >({
      query: () => ({
        url: "transaction/account",
        method: "GET",
        params: { code: "ICINRE" },
      }),
      transformResponse: (response: GenericResponse<IRecentTransaction[]>) => {
        const modifiedResult = response?.result.map((item, index) => ({
          ...item,
          sequentialId: index + 1,
        }));

        return {
          ...response,
          result: modifiedResult,
        };
      },
    }),

    getCommonTransactions: builder.query<
      GenericResponse<ICommonTransaction[]>,
      void
    >({
      query: () => ({
        url: "ctequery/commontransactions",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<ICommonTransaction[]>) => {
        const modifiedResult = response?.result.map((item, index) => ({
          ...item,
          sequentialId: index + 1,
        }));

        return {
          ...response,
          result: modifiedResult,
        };
      },
    }),

    getTransactionTypes: builder.query<string[], void>({
      query: () => ({
        url: "transaction/type",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),

    getTransactionModes: builder.query<string[], void>({
      query: () => ({
        url: "transaction/mode",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),

    addTransactionRecord: builder.mutation({
      query: (transactionData) => ({
        url: "transaction",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: transactionData,
      }),
      invalidatesTags: [Tags.MonthlyReport],
    }),
  }),
});

export const {
  useGetRecentTransactionsQuery,
  useGetAccountTransactionsQuery,
  useGetCommonTransactionsQuery,
  useGetTransactionTypesQuery,
  useGetTransactionModesQuery,

  useAddTransactionRecordMutation,
} = transactionsApi;
export default transactionsApi;
