import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { useGetMonthlyReportQuery } from "../services/reportsApi";
import { MainLoader } from "../common";

function MonthlyReportChart() {
  const { data, isLoading } = useGetMonthlyReportQuery();
  const [netChartData, setNetChartData] = useState({});
  const [totalChartData, setTotalChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (data) {
      const labels = data.result.map((item) => item.yearMonth.toString());
      const totalIncome = data.result.map((item) => item.totalIncome);
      const totalExpense = data.result.map((item) => item.totalExpense);
      const totalSavings = data.result.map((item) => item.totalSavings);
      const netIncome = data.result.map((item) => item.netIncome);
      const netExpense = data.result.map((item) => item.netExpense);
      const netSavings = data.result.map((item) => item.netSavings);

      const totalReport = {
        labels: labels,
        datasets: [
          {
            label: "Total Income",
            data: totalIncome,
            borderColor: "#66BB6A",
            fill: false,
            tension: 0.4,
          },
          {
            label: "Total Expense",
            data: totalExpense,
            borderColor: "#FF6384",
            fill: false,
            tension: 0.4,
          },
          {
            label: "Total Savings",
            data: totalSavings,
            borderColor: "#9C27B0",
            fill: false,
            tension: 0.4,
          },
        ],
      };

      const netReport = {
        labels: labels,
        datasets: [
          {
            label: "Net Income",
            data: netIncome,
            borderColor: "#42A5F5",
            fill: false,
            tension: 0.4,
          },
          {
            label: "Net Expense",
            data: netExpense,
            borderColor: "#E91E63",
            fill: false,
            tension: 0.4,
          },
          {
            label: "Net Savings",
            data: netSavings,
            borderColor: "#FFA726",
            fill: false,
            tension: 0.4,
          },
        ],
      };

      const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "FINANCIAL OVERVIEW",
            font: {
              size: 16,
            },
            padding: {
              top: 20,
              bottom: 20,
            },
          },
          legend: {
            position: "top",
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Year-Month",
            },
          },
          y: {
            title: {
              display: true,
              text: "Amount",
            },
          },
        },
      };

      setNetChartData(netReport);
      setTotalChartData(totalReport);
      setChartOptions(options);
    }
  }, [data]);

  // Options for customizing the chart

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <Chart
              type="line"
              data={netChartData}
              options={chartOptions}
              style={{ flex: "1 1 45%", maxWidth: "45%", height: "40vh" }}
            />
            <Chart
              type="line"
              data={totalChartData}
              options={chartOptions}
              style={{ flex: "1 1 45%", maxWidth: "45%", height: "40vh" }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default MonthlyReportChart;
