import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Tags } from "../store/tags";

export const financeApi = createApi({
  reducerPath: "financeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.financemanager.co.in/api",
  }),
  tagTypes: [Tags.MonthlyReport],
  endpoints: () => ({}), // Empty endpoints initially
});

export default financeApi;
