import React from "react";
import { SmartCardItem } from "../dashboard";
import { MonthlyReport } from "../../interfaces";

interface SmartCardProps {
  data: MonthlyReport | undefined;
  isLoading: boolean;
}

const SmartCard: React.FC<SmartCardProps> = (props) => {
  const smartCardItems = [
    {
      id: 1,
      title: "EXPENSES",
      amount: props?.isLoading
        ? "Loading..."
        : `₹ ${props?.data?.totalExpense?.toFixed(2)}`,
      iconClass: "ni ni-money-coins icon-color",
      trend: "+25%",
      iconColor: "icon-style expense-color",
    },
    {
      id: 2,
      title: "INCOME",
      amount: props?.isLoading
        ? "Loading..."
        : `₹ ${props?.data?.totalIncome?.toFixed(2)}`,
      iconClass: "ni ni-world icon-color",
      trend: "+75%",
      iconColor: "icon-style income-color",
    },
    {
      id: 3,
      title: "SAVINGS",
      amount: props?.isLoading
        ? "Loading..."
        : `₹ ${props?.data?.totalSavings?.toFixed(2)}`,
      iconClass: "ni ni-paper-diploma icon-color",
      trend: "-55%",
      iconColor: "icon-style savings-color",
    },
    {
      id: 4,
      title: "ZAKAT",
      amount: "$5500.00",
      iconClass: "ni ni-cart icon-color",
      trend: "+5%",
      iconColor: "icon-style zakat-color",
    },
  ];

  return (
    <div className="smartcard-container">
      {smartCardItems.map((item) => (
        <SmartCardItem
          key={item.id} // important to avoid console warning
          heading={item.title}
          dataPoint={item.amount}
          trend={item.trend}
          iconClass={item.iconClass}
          iconColor={item.iconColor}
        />
      ))}
    </div>
  );
};

export default SmartCard;
