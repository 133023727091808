import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AccountData, GenericResponse } from "../interfaces";

const accountsApi = createApi({
  reducerPath: "accountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.financemanager.co.in/api",
  }),
  endpoints: (builder) => ({
    getAllAccount: builder.query<GenericResponse<AccountData[]>, void>({
      query: () => ({
        url: "accounts",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<AccountData[]>) => {
        const sortedResponse =
          response.result && Array.isArray(response.result)
            ? response.result.sort((a, b) => {
                if (b.currencyId !== a.currencyId) {
                  return b.currencyId - a.currencyId;
                }
                return b.balance - a.balance;
              })
            : [];

        const modifiedResult = sortedResponse.map((item, index) => ({
          ...item,
          sequentialId: index + 1,
        }));

        return {
          ...response,
          result: modifiedResult,
        };
      },
    }),
  }),
});

export const { useGetAllAccountQuery } = accountsApi;
export default accountsApi;
