import LoginPage from "../layouts/LoginPage";
import Register from "../layouts/Register";
import { HashRouter, Routes, Route } from "react-router-dom";
import { UserLayout, CoreLayout } from "../layouts";
import { Dashboard, Transactions } from "../pages";

function AppRouter() {
  return (
    <HashRouter>
      <Routes>
        <Route element={<UserLayout />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
        </Route>

        <Route element={<CoreLayout />}>
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default AppRouter;
