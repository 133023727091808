import "../styles/dashboard/overview.css";
import "../styles/dashboard/smartcarditem.css";
import "../styles/dashboard/table.css";
import "../styles/fonts/nucleo-icons.css";
import "../styles/fonts/nucleo-svg.css";

import { SmartCard } from "../components/smartcard";
import WelcomeUser from "../components/header/WelcomeUser";
import { hocAuthenticate } from "../components/hoc";
import { Accounts, MonthlyReportChart, RecentTransactions } from "../data";
import { useGetMonthlyReportQuery } from "../services/reportsApi";

function Dashboard() {
  const { data, isLoading } = useGetMonthlyReportQuery();
  const currentData = data?.result[0];

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <SmartCard data={currentData} isLoading={isLoading} />
      </div>
      <div className="visualizer-panel">
        <div className="graphs-panel">
          <MonthlyReportChart />
        </div>
      </div>
      <div className="data-panel">
        <div className="data-viewport">
          <Accounts />
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default hocAuthenticate(Dashboard);
