import { configureStore } from "@reduxjs/toolkit";
import {
  authApi,
  financeApi,
  staticApi,
  accountsApi,
  componentsApi,
} from "../services";
import { userAuthReducer } from "./slice/userAuthSlice";

const store = configureStore({
  reducer: {
    userAuthStore: userAuthReducer,
    [authApi.reducerPath]: authApi.reducer,
    [financeApi.reducerPath]: financeApi.reducer,
    [staticApi.reducerPath]: staticApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [componentsApi.reducerPath]: componentsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(financeApi.middleware)
      .concat(staticApi.middleware)
      .concat(accountsApi.middleware)
      .concat(componentsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
